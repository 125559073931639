import React from "react"
import Img from "gatsby-image"
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"
import { grey, red } from "@material-ui/core/colors"

import "./hero.css"

const useStyles = makeStyles(theme => ({
  paragraph: {
    ...theme.typography.body1,
  },
  paper: {
    padding: theme.spacing(1.5),
  },
  hero: {
    background: grey[300],
    paddingTop: theme.spacing(4),
    borderTop: `10px solid ${grey[700]}`,
  },
  title: {
    display: "block",
    color: red[600],
    fontWeight: 100,
  },
  contactInfo: {
    color: grey[600],
    fontSize: "1rem",
    display: "block",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignContent: "space-between",
  },
}))

const Hero = props => {
  const classes = useStyles()
  const { data } = props
  return (
    <>
      <div className={classes.hero}>
        <Container component="main" className={classes.content} maxWidth="md">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={9}>
              <Typography component="div">
                <Box
                  fontSize="h4.fontSize"
                  // textAlign="center"
                  m={1}
                  className={[classes.title, "arvo"].join(" ")}
                >
                  John Corbett
                </Box>
              </Typography>

              <Typography variant="body1" className={classes.contactInfo}>
                john@corbettcommunications.com
                <br />
                @johnVcorbett
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <Paper className={classes.paper} elevation={4}> */}
              <Img fluid={data.businessHead.childImageSharp.fluid} />
              {/* </Paper> */}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Hero
