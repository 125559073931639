import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Releases from "../components/releases"
import FrontPolls from "../components/frontPolls"
// import ContactForm from "../components/contactForm"
import Hero2 from "../components/hero2"
import Bio from "../components/bio"
import BookEnds from "../components/bookEnds"

const IndexPage = ({ data }) => (
  <>
    <SEO
      title="Corbett Communications"
      description="polling, polls, focus groups, market research, analysis, program evaluation, usage and attitude, segmentation, qualitative, quantitative, public opinion, political polls, political polling, politics, travel and tourism, Gallup, Forum, Mainstreet, Campaign Research, new products, concept research, idea generation, Doug Ford, Ontario, Canada, Trudeau, Scheer, Singh, Liberals, Conservatives, Greens, NDP, Bloc Quebecois, People’s Party of Canada, Progressive Conservatives"
    />
    <Hero2 fluid={data.placeholderImage.childImageSharp.fluid} />
    <Layout>
      <Releases data={data.releases} />
      <Bio data={data} />
      {/* <ContactForm /> */}
      <FrontPolls data={data.gallery.edges} />
      <BookEnds
        dataOne={data.cartoonOne.childImageSharp.fluid}
        dataTwo={data.cartoonTwo.childImageSharp.fluid}
      />
    </Layout>
  </>
)

export default IndexPage

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "CorComLogo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    businessHead: file(relativePath: { eq: "JCheadshot2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whiteRaven: file(relativePath: { eq: "raven-white.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cartoonOne: file(relativePath: { eq: "Trudeau-Poll-Cartoon-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cartoonTwo: file(relativePath: { eq: "Trudeau-Poll-Cartoon-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    releases: allDataJson(sort: { fields: releases___date, order: ASC }) {
      edges {
        node {
          releases {
            date
            link
            title
            headline
          }
        }
      }
    }
    gallery: allFile(
      filter: { sourceInstanceName: { eq: "gallery" } }
      sort: { fields: name, order: DESC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
