import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { grey, red } from "@material-ui/core/colors"
import "./hero.css"

import ReleaseCards from "./releaseCards"

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: grey[700],
  },
  backgroundReleases: {
    padding: theme.spacing(4, 0),
    borderTop: `10px solid ${grey[700]}`,
    backgroundColor: grey[100],
  },
  title: {
    color: red[600],
    fontWeight: 100,
  },
  list: {
    fontSize: "0.85rem",
  },
  cardTitle: {
    fontSize: 14,
    color: red[600],
  },
  link: {
    textDecoration: "none",
  },
  releaseTitle: {
    color: grey[600],
    fontSize: 12,
  },
  moreReleases: {
    marginTop: theme.spacing(5),
  },
}))

const Releases2 = props => {
  const classes = useStyles()
  const { data } = props
  const releases = data.edges[0].node.releases

  return (
    <div className={classes.backgroundReleases}>
      <Container component="main" className={classes.main} maxWidth="md">
        <Typography component="div">
          <Box
            fontSize="h4.fontSize"
            textAlign="center"
            m={1}
            className={[classes.title, "arvo"].join(" ")}
          >
            Recent Media Releases
          </Box>
        </Typography>

        <Box textAlign="center" m={1} className={classes.list}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <ReleaseCards releases={releases} limit={6} />
              </Grid>

              <Link to="/releases" className={classes.link}>
                <Button className={classes.moreReleases}>
                  More Releases ...
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default Releases2
