import React from "react"
import { Container, Grid, makeStyles, Typography } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

import Hero from "./hero"

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: grey[300],
    padding: theme.spacing(4, 0),
  },
  paragraph: {
    ...theme.typography.body1,
    fontSize: "0.9rem",
    color: grey[700],
  },
  bio: {
    fontSize: "0.9rem",
    color: grey[600],
  },
}))

const Bio = props => {
  const classes = useStyles()
  const { data } = props
  return (
    <>
      <Hero data={data} />
      <div className={classes.content}>
        <Container component="main" maxWidth="md">
          <Grid container alignItems="flex-start" spacing={4}>
            <Grid item xs={12} sm={6}>
              <p className={classes.paragraph}>
                John Corbett began his career in market research in 1984 as a
                coder at Ruston/Tomany & Associates. He became a project
                director there, joined Gallup Canada in 1989, where he became a
                consultant, then senior consultant, then joined Research
                Dimensions in 1992 where he became Vice President. John left
                Research Dimensions in 2000 to form his own company, Corbett
                Communications.
              </p>
              <p className={classes.paragraph}>
                As a consultant, John directed the Forum Poll from 2010 to 2017,
                then established and ran the Campaign Research Poll from January
                2017 to October of that year. John has also worked with
                Mainstreet Research.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className={classes.paragraph}>
                A public opinion pollster for more than 35 years, John keeps his
                finger on the pulse of the nation. Specific areas of expertise
                are politics, program evaluation, customer satisfaction, travel
                and tourism, cannabis, new products, unions and member
                associations. In addition to quantitative research expertise,
                John is an accomplished focus group moderator.
              </p>
              <p className={classes.paragraph}>
                Corbett Communications profiles public opinion so that you can
                shape it. We answer questions you haven’t asked yet. We know
                more than we’re letting on.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Bio
