/* eslint-disable */
import React, { useState } from "react"
import Img from "gatsby-image"
import {
  Box,
  Container,
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { grey, red } from "@material-ui/core/colors"
import "./hero.css"

const useStyles = makeStyles(theme => ({
  background: {
    padding: theme.spacing(4, 0),
    borderTop: `10px solid ${grey[700]}`,
    backgroundColor: grey[100],
  },
  title: {
    color: red[600],
    fontWeight: 100,
  },
  paragraph: {
    ...theme.typography.body1,
    fontSize: "0.9rem",
    color: grey[700],
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cursor: {
    cursor: "pointer",
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const BookEnds = props => {
  const classes = useStyles()
  const { dataOne, dataTwo } = props
  const [open, setOpen] = useState(false)
  const [imageTile, setImageTile] = useState("")

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  function handleClickOpen(tile) {
    console.log("open ", tile)
    setImageTile(tile)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
    setImageTile("")
  }

  return (
    <div className={classes.background}>
      <Container component="main" className={classes.main} maxWidth="md">
        <Typography component="div">
          <Box
            fontSize="h4.fontSize"
            textAlign="center"
            m={1}
            className={[classes.title, "arvo"].join(" ")}
          >
            Book Ends
          </Box>
        </Typography>
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item xs={12}>
            <p className={classes.paragraph}>
              The first cartoon shown here (January, 2012) is based on a Forum
              Poll John did, the first showing that the Liberals, with Justin
              Trudeau as leader, could defeat the Conservatives under Stephen
              Harper.
            </p>
            <p className={classes.paragraph}>
              The second cartoon, from seven years later (February, 2019) is
              based on a Campaign Research Poll from a design John created,
              showing Trudeau’s approvals declining in the midst of the SNC
              Lavalin scandal.
            </p>
            <p className={classes.paragraph} align="center">
              Both cartoons are by Gary Clement, for The National Post.
            </p>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={4}>
          <Grid
            item
            xs={6}
            className={classes.cursor}
            onClick={() => handleClickOpen(dataOne)}
          >
            <Img fluid={dataOne} />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cursor}
            onClick={() => handleClickOpen(dataTwo)}
          >
            <Img fluid={dataTwo} />
          </Grid>
        </Grid>
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <div className={classes.close}>
            <Button onClick={handleClose}>close</Button>
          </div>
          {imageTile && <Img fluid={imageTile} />}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default BookEnds
