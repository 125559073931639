import React, { useState } from "react"
import Img from "gatsby-image"
import Icon from "@mdi/react"
import moment from "moment"
import { mdiClose } from "@mdi/js"
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  GridList,
  GridListTile,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { grey, red } from "@material-ui/core/colors"
import "./hero.css"

const useStyles = makeStyles(theme => ({
  paragraph: {
    ...theme.typography.body1,
    textAlign: "center",
    fontSize: "0.75rem",
    color: grey[700],
  },
  headline: {
    fontSize: "1.5rem",
    fontStyle: "italic",
    color: red[600],
  },
  frontPolls: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    color: red[600],
    fontWeight: 100,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const galleryList = (data, classes) => {
  const [open, setOpen] = useState(false)
  const [imageTile, setImageTile] = useState("")
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  function handleClickOpen(tile) {
    setImageTile(tile)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
    setImageTile("")
  }

  const headline = imageTile => {
    const fileName = imageTile.node.name
    const fileName2 = fileName.split(":")
    const cartoon = fileName.split("-")
    let date
    let headline

    if (!imageTile) {
      return
    } else {
      if (imageTile && fileName2[2]) {
        date = moment(fileName2[0]).format("dddd, MMMM Do YYYY")
        headline = `"${fileName2[2]}"`
      } else if (cartoon) {
        date = moment(cartoon[1]).format("dddd, MMMM Do YYYY")
        headline = `${cartoon[2]}`
      }

      return (
        <>
          <p className={classes.paragraph}>
            {date}
            <br />
            <span className={classes.headline}>{headline}</span>
          </p>
        </>
      )
    }
  }

  return (
    <>
      <div className={classes.frontPolls}>
        <GridList cellHeight={200} className={classes.gridList} cols={3}>
          {data.map(tile => {
            const fileName = tile.node.name
            const fileName2 = fileName.split(":")
            return (
              <GridListTile
                key={tile.node.id}
                cols={tile.cols || 1}
                onClick={() => handleClickOpen(tile)}
                style={{ cursor: "pointer" }}
              >
                <Img
                  fluid={tile.node.childImageSharp.fluid}
                  alt={fileName2[2]}
                />
              </GridListTile>
            )
          })}
        </GridList>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <div className={classes.close}>
            <Button onClick={handleClose}>
              <Icon path={mdiClose} size={1} color="grey" />
              close
            </Button>
          </div>
          <Divider />
          {imageTile && headline(imageTile)}

          {imageTile && <Img fluid={imageTile.node.childImageSharp.fluid} />}
        </DialogContent>
      </Dialog>
    </>
  )
}

const FrontPolls = props => {
  const classes = useStyles()
  const { data } = props

  return (
    <>
      <Container component="main" className={classes.main} maxWidth="md">
        <Typography component="div">
          <Box
            fontSize="h4.fontSize"
            textAlign="center"
            m={1}
            className={[classes.title, "arvo"].join(" ")}
          >
            Front Page Polls
          </Box>
        </Typography>
        <p className={classes.paragraph}>
          Shown here are some of John’s recent front page polls (or A1s). Many
          are “above the fold”.
        </p>
        {galleryList(data, classes)}
      </Container>
    </>
  )
}

export default FrontPolls
